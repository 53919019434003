/**
 * 判断url是否是http或https 
 * @param {string} path
 * @returns {Boolean}
 */
export function isHttp(url) {
  return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1
}

/**
 * 判断path是否为外链
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}
/**
 * 手机号码格式校验
 * @returns
 */
export function isMobile(value) {
  const reg = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/
  return reg.test(value)
}

/**
 * 身份证格式校验
 * @returns
 */
export function isCardNo(num) {
  let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  let _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
  // 校验身份证：
  if (_IDRe18.test(num) || _IDre15.test(num)) {
    return true
  } else {
    return false
  }
}
// 验证正整数 范围(1-9999)
export function regNum(rule, value, callback) {
  const reg = /^([1-9]\d{0,3}|0)$/
  if (reg.test(value) || value === null) {
    callback()
  } else {
    callback(new Error('请输入3位正整数'))
  }
}
// 验证正整数 范围(0-99999999)
export function regNumStart0(rule, value, callback) {
  const reg = /^([0-9]\d{0,8}|0)$/
  if (reg.test(value) || value === null) {
    callback()
  } else {
    callback(new Error('请输入8位正整数'))
  }
}
// 验证手机号
export function checkPhone(rule, value, callback) {
  function isPoneAvailable(str) {
    const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    const myreg1 = /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/
    const myreg2 = /^\d{7,8}$/
    if (!myreg.test(str) && !myreg1.test(str)) {
      if (myreg2.test(str)) {
        return 'ADD'
      } else {
        return false
      }
    } else {
      return true
    }
  }
  if (!(value)) {
    callback(new Error('电话不能为空'))
  } else if (isPoneAvailable(value) === 'ADD') {
    callback(new Error('请添加区号; 形如:XXX-XXXXXXX'))
  } else if (isPoneAvailable(value)) {
    callback()
  } else {
    callback(new Error('非法电话'))
  }
}
// 默认有输入值，需要配合 { required: true, message: "请输入", trigger: "blur" } 一起使用
export function checkPhoneTwo(rule, value, callback) {
  function isPoneAvailable(str) {
    const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    const myreg1 = /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/
    const myreg2 = /^\d{7,8}$/
    if (!myreg.test(str) && !myreg1.test(str)) {
      if (myreg2.test(str)) {
        return 'ADD'
      } else {
        return false
      }
    } else {
      return true
    }
  }
  if (value) {
    if (isPoneAvailable(value) === 'ADD') {
      callback(new Error('请添加区号; 形如:XXX-XXXXXXX'))
    } else if (isPoneAvailable(value)) {
      callback()
    } else {
      callback(new Error('非法电话'))
    }
  } else {
    callback()
  }
}