
/*********************************
 ** Fn: 限制输入浮点数指令
 ** Author: qingmu
 *********************************/
const getInput = (el) => el instanceof HTMLInputElement ? el : el.querySelector('input')
let inputHandler = () => {}
function isPositiveInteger(s){//是否为正整数
    var re = /^[0-9]+$/ ;
    return re.test(s)
}
export default {
    beforeMount(el, binding, vnode) {
        // let input = vnode.el.children[0];
        let input = getInput(el)
        if (input.tagName !== 'INPUT') {
            input = input.querySelector('input');
        }
        if (!input) return;
        input.addEventListener('compositionstart', () => {
            vnode.inputLocking = true;
        });
        input.addEventListener('compositionend', () => {
            vnode.inputLocking = false;
            input.dispatchEvent(new Event('input'));
        });
        inputHandler = (e) => {
            e.preventDefault(); // 阻止掉默认的change事件
            if (vnode.inputLocking) {
                return;
            }
            let oldValue = input.value;
            let newValue = input.value;
            //得到第一个字符是否为负号
            var t = newValue.charAt(0);
            newValue = newValue.replace(/[^0-9.]/gi, '');
            newValue = newValue
                .replace('.', '$#$')
                .replace(/\./g, '')
                .replace('$#$', '.');
            //判断是否超出最大值
            let { max, decimal,minus } = binding.value||{}
            if(isPositiveInteger(binding.value)){
                max = binding.value
            }
            if (max !== undefined) {
                const _max = Number(max)
                if (Number(newValue) > max) {
                    let len = _max.toString().length - 1
                    newValue = Number(newValue.slice(0, len));
                    if (newValue > max) {
						newValue = newValue.toString()
						newValue = newValue.slice(0, newValue.length - 1)
					}
                    //如果第一位是负号，则允许添加
                    if (t == '-' && (binding.modifiers.minus || minus)) {
                        newValue = '-' + newValue;
                    }
                    input.value = newValue
                    input.dispatchEvent(new Event('input')); // 通知v-model更新
                    return
                }
            }
            const _decimal = Number(decimal) || 2; // 默认两位小数
            const reg = new RegExp(`^(\\-)*(\\d+)\\.(\\d{${_decimal}}).*$`);
            newValue = newValue.replace(reg, '$1$2.$3');
            if (newValue) {
                let arr = newValue.split('.');
                newValue = Number(arr[0]) + ((arr[1] === undefined || decimal == 0) ? '' : '.' + arr[1]); // 去掉开头多余的0
            }
            //如果第一位是负号，则允许添加
            if (t == '-' && (binding.modifiers.minus || minus)) {
                newValue = '-' + newValue;
            }
            // 判断是否需要更新，避免进入死循环
            if (newValue !== oldValue) {
                input.value = newValue;
                input.dispatchEvent(new Event('input')); // 通知v-model更新
            }
        },
            input.addEventListener('input', inputHandler, true);
        // input 事件无法处理小数点后面全是零的情况 因为无法确定用户输入的0是否真的应该清除，如3.02。放在blur中去处理
        input.addEventListener('blur', () => {
            let oldValue = input.value;
            let newValue = input.value;
            if (newValue) {
                newValue = Number(newValue).toString();
            }
            // 判断是否需要更新，避免进入死循环
            if (newValue !== oldValue) {
                input.value = newValue;
                input.dispatchEvent(new Event('input')); // 通知v-model更新
            }
        });
    },
    unmounted(el) {
        // 解除绑定的时候去除事件
        const input = getInput(el)
        input.removeEventListener('input', inputHandler, true)
    }

}
