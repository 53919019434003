<template>
   <div class="bfp-table__select">
      <div v-if="config.allSelect" style="padding-left: 18px">
         <el-checkbox
            v-model="isAll"
            @change="allcheck"
            v-loading="isAllLoading"
            :disabled="isAllLoading"
            v-if="config.allSelect"
            >全选 <span>已选 {{ checkNum }} 项</span></el-checkbox
         >
      </div>
      <el-table
         ref="jtable"
         class="jtable"
         :class="{ 'table-foot__sticky': !!props.footSticky }"
         :data="tableData"
         @select="handleSelect"
         @select-all="handleSelectAll"
         v-bind="{ ...$attrs }"
      >
         <slot>
            <!-- 多选 -->
            <el-table-column
               type="selection"
               width="50"
               align="center"
               fixed="left"
               v-bind="config.selection"
               v-if="config.selection?.visible"
            />

            <!-- 数据列 -->
            <template v-for="(column, k) of columns" :key="k">
               <el-table-column
                  v-if="column.visible"
                  v-bind="column"
                  :width="column.width"
                  :min-width="column.minWidth ? column.minWidth : '120'"
                  :show-overflow-tooltip="column.showOverflowTooltip === undefined ? true : column.showOverflowTooltip"
               >
                  <template #default="scope" v-if="$slots[`column-${column.prop}`]">
                     <slot :name="`column-${column.prop}`" v-bind="scope"></slot>
                  </template>
                  <template #header="scope" v-if="$slots[`header-${column.prop}`]">
                     <slot :name="`header-${column.prop}`" v-bind="scope"></slot>
                  </template>
               </el-table-column>
            </template>

            <!-- 操作列 -->
            <el-table-column label="操作" fixed="right" v-bind="config.op" v-if="config.op?.visible">
               <template #default="scope" v-if="$slots[`op-column`]">
                  <slot name="op-column" v-bind="scope"></slot>
               </template>
               <template #header="scope" v-if="$slots[`op-header`]">
                  <slot name="op-header" v-bind="scope"></slot>
               </template>
            </el-table-column>
         </slot>
      </el-table>
   </div>
</template>
<script setup>
import { objectMerge } from '@/utils/index'
const getRowIdentity = (row, rowKey) => {
   if (!row) throw new Error('Row is required when get row identity')
   if (typeof rowKey === 'string') {
      if (!rowKey.includes('.')) {
         return `${row[rowKey]}`
      }
      const key = rowKey.split('.')
      let current = row
      for (const element of key) {
         current = current[element]
      }
      return `${current}`
   } else if (typeof rowKey === 'function') {
      return rowKey.call(null, row)
   }
}

const $emit = defineEmits(['checkNumChange'])
const props = defineProps({
   columns: Object,
   option: {
      type: Object,
      default: () => {
         return {}
      }
   },
   total: {
      type: Number,
      default: 0
   },
   data: Array,
   isAll: Boolean,
   isAllLoading: Boolean,
   footSticky: {
      type: Boolean,
      default: false
   }
})

const { proxy } = getCurrentInstance()
const isAll = ref(props.isAll)
const jtable = ref()
// eslint-disable-next-line vue/no-dupe-keys
const data = reactive({
   // 配置
   config: {
      selection: {
         visible: true
      },
      op: {
         visible: true,
         align: 'center',
         minWidth: 100
      },
      allSelect: false
   },
   tableData: [],
   inverse: [],
   cacheCheckRowKeyList: []
})
const { config, tableData, inverse } = toRefs(data)
const checkNum = ref(0)
watch(checkNum, val => {
   $emit('checkNumChange', val)
})
function inverseCheckNum(selection) {
   checkNum.value = unref(isAll) ? props.total - unref(inverse).length : selection.length
}
watch(
   () => props.isAll,
   val => {
      console.log('表格内部修改====', val)
      isAll.value = val
      inverseCheckNum([])
   }
)
watch(
   () => props.data,
   val => {
      tableData.value = val || []
      if (config.value.allSelect) {
         nextTick(() => {
            setDataSelection(val)
         })
      }
      nextTick(() => {
         inverseCheckNum(jtable.value.getSelectionRows())
      })
   },
   {
      immediate: true,
      deep: true
   }
)
watch(
   () => props.option,
   val => {
      objectMerge(config.value, val)
   },
   {
      immediate: true,
      deep: true
   }
)
watch(
   () => props.columns,
   val => {
      proxy.$refs.jtable.doLayout()
   },
   {
      deep: true
   }
)

/**
 * 数据选中状态，去掉取消勾选的数据，其他的选中
 * @param {*} data
 */
function setDataSelection(data) {
   const rowKey = proxy.$attrs['row-key' || 'rowKey'] || 'id'
   if (isAll.value) {
      const selectList = data.filter(item => {
         const obj = inverse.value.find(i => i === item || getRowIdentity(i, rowKey) === getRowIdentity(item, rowKey))
         return obj ? false : true
      })
      for (let item of selectList) {
         proxy.$refs.jtable.toggleRowSelection(item, true)
      }
   }
}

function tableDoLayout() {
   proxy.$refs.jtable.doLayout()
}
/**
 * 全选/反选
 */
function allcheck(value) {
   if (value == false) {
      isAll.value = false
   }
   if (isAll.value) {
      // 全选
      inverse.value = []
      const selection = proxy.$refs.jtable.getSelectionRows()
      if (tableData.value.length !== selection.length) {
         proxy.$refs.jtable.toggleAllSelection()
      }
      inverseCheckNum()
   } else {
      // 反选
      proxy.$refs.jtable.clearSelection()
      inverseCheckNum([])
   }
   proxy.$emit('updateIsAll', isAll.value)
}

/**
 * 单页全选/反选
 * */
function handleSelectAll(selection) {
   const rowKey = proxy.$attrs['row-key' || 'rowKey'] || 'id'
   if (selection.length) {
      selection.map(item => {
         const index = inverse.value.findIndex(
            i => i === item || getRowIdentity(i, rowKey) === getRowIdentity(item, rowKey)
         )
         if (index > -1) {
            inverse.value.splice(index, 1)
         }
      })
   } else {
      tableData.value.map(item => {
         const index = inverse.value.findIndex(
            i => i === item || getRowIdentity(i, rowKey) === getRowIdentity(item, rowKey)
         )
         if (index === -1) {
            inverse.value.push(item)
         }
      })
   }
   inverseCheckNum(selection)
   proxy.$emit('AllSelectChange', inverse.value)
}

/**
 * 行选择
 * @param {*} rowKey
 * @param {*} row
 */
// function getRowKeyString(rowKey, row) {
//   let rowKeyString = rowKey;
//   if (typeof rowKey === "function") {
//     rowKeyString = rowKey(row);
//   }
//   return rowKeyString;
// }
function handleSelect(selection, row) {
   const rowKey = proxy.$attrs['row-key' || 'rowKey'] || 'id'
   const selectObj = selection.find(i => {
      const vNodeEqual = i === row
      const rowKeyValueEqual = getRowIdentity(i, rowKey) === getRowIdentity(row, rowKey)

      return vNodeEqual || rowKeyValueEqual
   })
   if (selectObj) {
      // 勾选
      const index = inverse.value.findIndex(i => i === row || getRowIdentity(i, rowKey) === getRowIdentity(row, rowKey))
      if (index > -1) {
         inverse.value.splice(index, 1)
      } else {
         inverse.value.push(row)
      }
   } else {
      // 反选
      inverse.value.push(row)
   }
   inverseCheckNum(selection)
   proxy.$emit('AllSelectChange', inverse.value)
}
defineExpose({
   allcheck,
   tableDoLayout,
   jtable,
   inverse,
   isAll,
   checkNum
})
</script>

<style lang="scss">
.bfp-table__select {
   .table-foot__sticky {
      .el-table__footer-wrapper {
         position: sticky;
         z-index: 3;
         bottom: 44px;
      }
   }
}
</style>
