import { createWebHistory, createRouter } from 'vue-router'
/* Layout */
import Layout from '@/layout'

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login'),
    hidden: true
  },
  {
    path: '/register',
    component: () => import('@/views/register'),
    hidden: true
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error/401'),
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: () => import('@/views/index'),
        name: 'Index',
        meta: { title: '首页', icon: 'dashboard', affix: true }
      }
    ]
  },
  {
    path: '/wechat',
    component: Layout,
    hidden: true,
    permissions: ['shop:shopProtocolInfo:add'],
    children: [
      {
        path: 'shopProtocolInfoAdd',
        component: () => import('@/views/shop/shopProtocolInfo/edit'),
        name: 'ShopProtocolInfoAdd',
        meta: { title: '新增协议', activeMenu: '/wechat/shopProtocolInfo' },
      },
      {
        path: 'shopProtocolInfoEdit',
        component: () => import('@/views/shop/shopProtocolInfo/edit'),
        name: 'ShopProtocolInfoEdit',
        meta: { title: '编辑协议', activeMenu: '/wechat/shopProtocolInfo' },
      },
    ]
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'profile',
        component: () => import('@/views/system/user/profile/index'),
        name: 'Profile',
        meta: { title: '个人中心', icon: 'user' }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/marketingActivity/couponManage/couponEdit/:id',
        hidden: true,
        component: () => import('@/views/marketingActivity/couponManage/list/edit'),
        name: 'CouponEdit',
        meta: { title: '优惠券编辑' }
      },
      {
        path: '/marketingActivity/couponManage/couponActiveDetail',
        hidden: true,
        component: () => import('@/views/marketingActivity/couponManage/active/edit'),
        name: 'CouponActiveDetail',
        meta: { title: '查看活动' }
      },
      {
        path: '/marketingActivity/couponManage/couponActiveAdd',
        hidden: true,
        component: () => import('@/views/marketingActivity/couponManage/active/edit'),
        name: 'CouponActiveAdd',
        meta: { title: '新建活动' }
      },
      {
        path: '/marketingActivity/couponManage/couponActiveCopy',
        hidden: true,
        component: () => import('@/views/marketingActivity/couponManage/active/edit'),
        name: 'CouponActiveCopy',
        meta: { title: '复制新建活动' }
      }
    ]
  }

]

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
  {
    path: "/shop",
    hidden: false,
    component: Layout,
    permissions: ['shop:shopGroup:add', 'shop:shopGroup:query', 'shop:shopGroup:edit', 'shop:shopGroup:import'],
    meta: { title: "商品", icon: 'setting', noCache: false, "link": null },
    children: [
      {
        path: 'shopGroupItem',
        component: () => import('@/views/shop/shopGroupItem/index'),
        name: 'ShopGroupItem',
        meta: { title: '组合商品新增', activeMenu: '/set/shopGroup', noCache: false },
      },
    ]
  },
  {
    path: "/order",
    hidden: false,
    component: Layout,
    permissions: ['order:orderInfoLease:query', 'order:orderInfoLease:look', 'order:orderInfoLease:detail', 'order:orderInfoLease:revert', 'order:orderInfoLease:edit', 'order:orderInfoLease:import'],
    meta: { title: "订单", icon: 'documentation', noCache: false, "link": null },
    children: [
      // {
      //   name: "OrderInfoLease",
      //   path: "orderInfoLease",
      //   component: () => import('@/views/order/orderInfoLease/index'),
      //   meta: { title: '租赁订单信息', activeMenu: '/order/orderInfoLease' }
      // },
      {
        path: 'orderInfoLeaseRevert',
        component: () => import('@/views/order/orderInfoLease/revert'),
        name: 'OrderInfoLeaseRevert',
        meta: { title: '租赁归还确认', activeMenu: '/order/orderInfoLease' },
      },
      {
        path: 'orderInfoLeaseEdit',
        component: () => import('@/views/order/orderInfoLease/edit'),
        name: 'OrderInfoLeaseEdit',
        meta: { title: '租赁领取确认', activeMenu: '/order/orderInfoLease' },
      },
      {
        path: 'orderInfoLeaseLook',
        component: () => import('@/views/order/orderInfoLease/look'),
        name: 'OrderInfoLeaseLook',
        meta: { title: '租赁订单详情', activeMenu: '/order/orderInfoLease' },
      },
      {
        path: 'orderInfoLeasePickingEdit',
        component: () => import('@/views/order/orderInfoLeasePicking/edit'),
        name: 'OrderInfoLeasePickingEdit',
        meta: { title: '拣货领取确认', activeMenu: '/order/orderInfoLeasePicking' },
      },
      {
        path: 'orderInfoLeasePickingLook',
        component: () => import('@/views/order/orderInfoLeasePicking/look'),
        name: 'OrderInfoLeasePickingLook',
        meta: { title: '拣货订单详情', activeMenu: '/order/orderInfoLeasePicking' },
      },
    ]
  },
  {
    path: "/stock",
    hidden: false,
    component: Layout,
    permissions: ['stock:stockStorageOrder:query', 'stock:stockStorageOrder:add', 'stock:stockStorageOrder:edit', 'stock:stockStorageOrder:import',
      'stock:stockOutboundOrder:query', 'stock:stockOutboundOrder:add', 'stock:stockOutboundOrder:edit', 'stock:stockOutboundOrder:import'],
    meta: { "title": "库存", "icon": 'tab', noCache: false, "link": null },
    children: [
      {
        name: "StockStorageOrder",
        path: "stockStorageOrder",
        component: () => import('@/views/stock/stockStorageOrder/index'),
        meta: { title: '入库单', activeMenu: '/stock/stockStorageOrder' }
      },
      {
        path: 'stockStorageOrderAdd',
        component: () => import('@/views/stock/stockStorageOrder/edit'),
        name: 'StockStorageOrderAdd',
        meta: { title: '入库单新增', activeMenu: '/stock/stockStorageOrder', noCache: false },
      },
      {
        path: 'stockStorageOrderEdit',
        component: () => import('@/views/stock/stockStorageOrder/edit'),
        name: 'StockStorageOrderEdit',
        meta: { title: '入库质检编辑', activeMenu: '/stock/stockStorageOrder' },
      },
      {
        path: 'stockStorageOrderLook',
        component: () => import('@/views/stock/stockStorageOrder/edit'),
        name: 'StockStorageOrderLook',
        meta: { title: '入库单详情', activeMenu: '/stock/stockStorageOrder' },
      },
      {
        name: "StockOutboundOrder",
        path: "stockOutboundOrder",
        component: () => import('@/views/stock/stockOutboundOrder/index'),
        meta: { title: '出库单', activeMenu: '/stock/stockOutboundOrder' }
      },
      {
        path: 'stockOutboundOrderAdd',
        component: () => import('@/views/stock/stockOutboundOrder/edit'),
        name: 'StockOutboundOrderAdd',
        meta: { title: '出库单新增', activeMenu: '/stock/stockOutboundOrder', noCache: false },
      },
      {
        path: 'stockOutboundOrderEdit',
        component: () => import('@/views/stock/stockOutboundOrder/edit'),
        name: 'StockOutboundOrderEdit',
        meta: { title: '拣货出库编辑', activeMenu: '/stock/stockOutboundOrder' },
      },
      {
        path: 'stockOutboundOrderLook',
        component: () => import('@/views/stock/stockOutboundOrder/edit'),
        name: 'StockOutboundOrderLook',
        meta: { title: '出库单详情页', activeMenu: '/stock/stockOutboundOrder' },
      },
    ]
  },
  {
    path: '/price',
    component: Layout,
    hidden: true,
    permissions: ['shop:shopCampPrice:query', 'shop:shopCampPrice:add', 'shop:shopCampPrice:edit', 'shop:shopCampPrice:import'],
    children: [
      {
        path: 'shopCampPriceDetail',
        component: () => import('@/views/shop/shopCampPrice/edit'),
        name: 'ShopCampPriceDetail',
        meta: { title: '价格详情', activeMenu: '/price/shopCampPrice' },
      },
    ]
  },
  {
    path: '/price',
    component: Layout,
    hidden: true,
    permissions: ['shop:shopCampPrice:edit'],
    children: [
      {
        path: 'shopCampPriceEdit',
        component: () => import('@/views/shop/shopCampPrice/edit'),
        name: 'ShopCampPriceEdit',
        meta: { title: '编辑价格', activeMenu: '/price/shopCampPrice' },
      },
    ]
  },
  {
    path: '/lease',
    component: Layout,
    hidden: true,
    permissions: ['shop:leaseProduct:add', 'shop:leaseProduct:edit'],
    children: [
      {
        path: 'leaseProductEdit',
        component: () => import('@/views/shop/leaseProduct/edit'),
        name: 'LeaseProductEdit',
        meta: { title: '编辑租赁', activeMenu: '/lease/leaseProduct' },
      },
    ]
  },
  {
    path: '/reserve',
    component: Layout,
    hidden: true,
    permissions: ['shop:shopCampProduct:query', 'shop:shopCampProduct:add', 'shop:shopCampProduct:edit', 'shop:shopCampProduct:import'],
    children: [
      {
        path: 'shopCampProductAdd',
        component: () => import('@/views/shop/shopCampProduct/edit'),
        name: 'ShopCampProductAdd',
        meta: { title: '新增营区', activeMenu: '/reserve/shopCampProduct' },
      },
    ]
  },
  {
    path: '/reserve',
    component: Layout,
    hidden: true,
    permissions: ['shop:shopCampProduct:edit'],
    children: [
      {
        path: 'shopCampProductEdit',
        component: () => import('@/views/shop/shopCampProduct/edit'),
        name: 'ShopCampProductEdit',
        meta: { title: '编辑营区', activeMenu: '/reserve/shopCampProduct' },
      },
    ]
  },
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:user:edit'],
    children: [
      {
        path: 'role/:userId(\\d+)',
        component: () => import('@/views/system/user/authRole'),
        name: 'AuthRole',
        meta: { title: '分配角色', activeMenu: '/system/user' }
      }
    ]
  },
  {
    path: '/system/role-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:role:edit'],
    children: [
      {
        path: 'user/:roleId(\\d+)',
        component: () => import('@/views/system/role/authUser'),
        name: 'AuthUser',
        meta: { title: '分配用户', activeMenu: '/system/role' }
      }
    ]
  },
  {
    path: '/system/dict-data',
    component: Layout,
    hidden: true,
    permissions: ['system:dict:list'],
    children: [
      {
        path: 'index/:dictId(\\d+)',
        component: () => import('@/views/system/dict/data'),
        name: 'Data',
        meta: { title: '字典数据', activeMenu: '/system/dict' }
      }
    ]
  },
  {
    path: '/monitor/job-log',
    component: Layout,
    hidden: true,
    permissions: ['monitor:job:list'],
    children: [
      {
        path: 'index',
        component: () => import('@/views/monitor/job/log'),
        name: 'JobLog',
        meta: { title: '调度日志', activeMenu: '/monitor/job' }
      }
    ]
  },
  {
    path: '/tool/gen-edit',
    component: Layout,
    hidden: true,
    permissions: ['tool:gen:edit'],
    children: [
      {
        path: 'index/:tableId(\\d+)',
        component: () => import('@/views/tool/gen/editTable'),
        name: 'GenEdit',
        meta: { title: '修改生成配置', activeMenu: '/tool/gen' }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
});

export default router;