<template>
   <div>
      <el-checkbox v-model="isAll" @change="allcheck" v-if="config.allSelect">全选</el-checkbox>
      <el-table
         ref="jtable"
         class="jtable"
         :data="tableData"
         @select="handleSelect"
         @select-all="handleSelectAll"
         v-bind="{ ...$attrs }"
      >
         <slot>
            <!-- 多选 -->
            <el-table-column
               type="selection"
               width="50"
               align="center"
               fixed="left"
               v-bind="config.selection"
               v-if="config.selection?.visible"
            />

            <!-- 数据列 -->
            <template v-for="(column, k) of columns" :key="k">
               <el-table-column
                  v-if="column.visible"
                  v-bind="column"
                  :width="column.width"
                  :min-width="column.minWidth ? column.minWidth : '120'"
                  :show-overflow-tooltip="column.showOverflowTooltip === undefined ? true : column.showOverflowTooltip"
               >
                  <template #default="scope" v-if="$slots[`column-${column.prop}`]">
                     <slot :name="`column-${column.prop}`" v-bind="scope"></slot>
                  </template>
                  <template #header="scope" v-if="$slots[`header-${column.prop}`]">
                     <slot :name="`header-${column.prop}`" v-bind="scope"></slot>
                  </template>
               </el-table-column>
            </template>

            <!-- 操作列 -->
            <el-table-column label="操作" fixed="right" v-bind="config.op" v-if="config.op?.visible">
               <template #default="scope" v-if="$slots[`op-column`]">
                  <slot name="op-column" v-bind="scope"></slot>
               </template>
               <template #header="scope" v-if="$slots[`op-header`]">
                  <slot name="op-header" v-bind="scope"></slot>
               </template>
            </el-table-column>
         </slot>
      </el-table>
   </div>
</template>
<script setup>
import { objectMerge } from '@/utils/index'
const props = defineProps({
   columns: Object,
   option: {
      type: Object,
      default: () => {
         return {}
      }
   },
   data: Array,
   isAll: Boolean
})

const { proxy } = getCurrentInstance()
const isAll = ref(props.isAll)
const data = reactive({
   // 配置
   config: {
      selection: {
         visible: true
      },
      op: {
         visible: true,
         align: 'center',
         minWidth: 100
      },
      allSelect: false
   },
   tableData: [],
   inverse: []
})
const { config, tableData, inverse } = toRefs(data)
watch(
   () => props.data,
   val => {
      tableData.value = val || []
      if (config.value.allSelect) {
         nextTick(() => {
            setDataSelection(val)
         })
      }
      // proxy.$refs.jtable.doLayout()
   },
   {
      immediate: true,
      deep: true
   }
)
watch(
   () => props.option,
   val => {
      objectMerge(config.value, val)
   },
   {
      immediate: true,
      deep: true
   }
)

/**
 * 数据选中状态，去掉取消勾选的数据，其他的选中
 * @param {*} data
 */
function setDataSelection(data) {
   if (isAll.value) {
      const selectList = data.filter(item => {
         const obj = inverse.value.find(
            i =>
               i === item ||
               i[proxy.$attrs['row-key' || 'rowKey'] || 'id'] === item[proxy.$attrs['row-key' || 'rowKey'] || 'id']
         )
         return obj ? false : true
      })
      for (let item of selectList) {
         proxy.$refs.jtable.toggleRowSelection(item, true)
      }
   }
}

/**
 * 全选/反选
 */
function allcheck() {
   if (isAll.value) {
      // 全选
      inverse.value = []
      const selection = proxy.$refs.jtable.getSelectionRows()
      if (tableData.value.length !== selection.length) {
         proxy.$refs.jtable.toggleAllSelection()
      }
   } else {
      // 反选
      proxy.$refs.jtable.clearSelection()
   }
   proxy.$emit('update:isAlll', isAll.value)
}

/**
 * 单页全选/反选
 * */
function handleSelectAll(selection) {
   if (selection.length) {
      selection.map(item => {
         const index = inverse.value.findIndex(
            i =>
               i === item ||
               i[proxy.$attrs['row-key' || 'rowKey'] || 'id'] === item[proxy.$attrs['row-key' || 'rowKey'] || 'id']
         )
         if (index > -1) {
            inverse.value.splice(index, 1)
         }
      })
   } else {
      tableData.value.map(item => {
         const index = inverse.value.findIndex(
            i =>
               i === item ||
               i[proxy.$attrs['row-key' || 'rowKey'] || 'id'] === item[proxy.$attrs['row-key' || 'rowKey'] || 'id']
         )
         if (index === -1) {
            inverse.value.push(item)
         }
      })
   }
}

/**
 * 行选择
 * @param {*} selection
 * @param {*} row
 */
function handleSelect(selection, row) {
   const obj = selection.find(
      i =>
         i === row ||
         i[proxy.$attrs['row-key' || 'rowKey'] || 'id'] === row[proxy.$attrs['row-key' || 'rowKey'] || 'id']
   )
   if (obj) {
      // 勾选
      const index = inverse.value.findIndex(
         i =>
            i === row ||
            i[proxy.$attrs['row-key' || 'rowKey'] || 'id'] === row[proxy.$attrs['row-key' || 'rowKey'] || 'id']
      )
      if (index > -1) {
         inverse.value.splice(index, 1)
      }
   } else {
      // 反选
      inverse.value.push(row)
   }
   proxy.$emit('AllSelectChange', inverse.value)
}
</script>
